var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.intf.serviceType.title.indexHeadTitle`
                  ),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAddTabs,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "grid-head-btn": _vm.gridHeadBtn,
          "search-columns": _vm.searchColumns,
        },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-row-detail-click": _vm.rowDetail,
          "row-test": _vm.testConn,
          "row-remove": _vm.rowRemove,
          "row-edit": _vm.rowEdit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }